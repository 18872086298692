<template>
    <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="validate">
        <v-layout wrap>
            <v-flex xs12>
                <v-text-field label="Nome" v-model="item.name" :rules="[rules.required]" />
            </v-flex>
            <v-flex xs12>
                <v-text-field label="Sigla" v-model="item.initials" :rules="[rules.required]" />
            </v-flex>
            <v-flex xs12>
                <v-btn :loading="lock" :disabled="!valid" color="success" @click="validate" style="width: 100%;">Salvar</v-btn>
            </v-flex>
        </v-layout>
    </v-form>
</template>

<script>
import { mask } from "vue-the-mask";
import rules from "@/helpers/rules";

export default {
    directives: { mask },

    props: {
        itemToEdit: {
            type: Object,
        },
        lock: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            rules,
            valid: true,
            itemClean: {
                name: "",
                initials: "",
            },
            item: { ...this.itemClean },
        };
    },

    watch: {
        itemToEdit: {
            immediate: true,
            handler(newVal) {
                if (this.$refs.form) {
                    this.$refs.form.reset();
                }

                if (newVal == null) {
                    this.item = { ...this.itemClean };
                } else {
                    this.item = { ...newVal };
                }
            },
        },
    },

    methods: {
        validate() {
            if (this.$refs.form.validate()) {
                this.$emit("save", this.item);
            }
        },
    },
};
</script>